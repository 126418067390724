// Constants
import { QUERY_LIMITS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DataColumns from '@/components/ui/DataColumns'
import PlacesListItem from '@/components/elements/places/PlacesListItem'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getAnalyticsDataByBrandId } from '@/services/company'
import { getEveryPlaces } from '@/services/place'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
// filters
import { formatDate, formatNumber } from '@/filters'

export default {
  name: 'Dashboard',
  components: {
    CardContainer,
    DataColumns,
    PlacesListItem,
    VuetifyContentLoading,
    VuetifyDataTable,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      // statistics
      visitStatistics: [],
      registeredPlaces: [],
      // date form
      dates: [],
      showDatePicker: false,
      // others
      processingRequestVisit: false,
      processingRequestRegister: false,
      // Table
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'URL',
          value: 'url'
        },
        {
          text: 'Creado',
          value: 'createTimestamp'
        },
        { text: 'Acciones', align: 'center' }
      ],
      placesRegistered: []
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Fechas formateadas para el datepicker
     *
     * @return {string}
     */
    datesFormatted() {
      if (!isNil(this.dates) && this.dates.length === 2) {
        return `${formatDate(this.dates[0], 'DD/MM/YYYY')} - ${formatDate(
          this.dates[1],
          'DD/MM/YYYY'
        )}`
      }
      return
    },
    /**
     * Opciones para el "v-date-picker"
     *
     * @return {object}
     */
    vDatePickerOptions() {
      // Fechas iniciales y finales
      const today = new Date()
      const maxDate = new Date()

      // Fecha máxima
      maxDate.setDate(today.getDate() - 1)

      return {
        color: 'secondary',
        'first-day-of-week': 1,
        max: formatDate(maxDate, 'YYYY-MM-DD'),
        'no-title': true,
        range: true,
        scrollable: true
      }
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  watch: {
    // Cuando los datos de la comañía ya están listos
    async companyData(value) {
      if (!isNil(value)) {
        // Fechas por defecto
        this.setDefaultDates()
        // Ultimos establecimientos
        this.getLastPlacesRegistered()
      }
    },
    // Cada vez que la fecha cambia
    async dates(value) {
      if (!isNil(value) && value.length === 2) {
        // Ocultamos datepicker
        this.showDatePicker = false
        // Obtenemos datos
        await this.getVisitStatistics()
      }
    }
  },
  methods: {
    /**
     * Obtenemos todos datos/componentes necesarios
     * para la carga inicial
     */
    async getEveryNeededData() {
      if (!isNil(this.companyData)) {
        // Fechas por defecto
        this.setDefaultDates()
        // Ultimos establecimientos
        this.getLastPlacesRegistered()
      }
    },
    /**
     * Establecemos las fechas por defecto
     */
    setDefaultDates() {
      // Fechas iniciales y finales
      const today = new Date()
      const fromDate = new Date()
      const toDate = new Date()

      fromDate.setDate(1) // Inicio del mes
      toDate.setDate(today.getDate() - 1) // Ayer

      this.dates = [formatDate(fromDate, 'YYYY-MM-DD'), formatDate(toDate, 'YYYY-MM-DD')]
    },
    /**
     * Obtenemos los datos de GA de la brand (todos los establecimientos asociados)
     */
    async getVisitStatistics() {
      try {
        // Loading
        this.processingRequestVisit = true
        // Obtenemos datos de visitas de la brand
        await this.setVisitStatistics()
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequestVisit = false
      }
    },
    /**
     * Obtenemos datos de visitas
     */
    async setVisitStatistics() {
      const data = await getAnalyticsDataByBrandId(
        this.companyData.id,
        this.dates[0],
        this.dates[1]
      )

      this.visitStatistics = [
        {
          label: 'Usuarios',
          value: formatNumber(get(data, 'totalUsers', 0)),
          ratio: get(data, 'totalUsersIncrementPeriods', 0)
        },
        {
          label: 'Visitas totales',
          value: formatNumber(get(data, 'totalSessions', 0)),
          ratio: get(data, 'totalSessionsIncrementPeriods', 0)
        },
        {
          label: 'Visitas QR (establecimiento)',
          value: formatNumber(get(data, 'totalVisitsQR', 0))
        },
        {
          label: 'Visitas Enlace (otros medios)',
          value: formatNumber(get(data, 'totalVisitsURL', 0))
        }
      ]
    },
    /**
     * Establecemos el array de "placesRegistered" (para la tabla)
     */
    async getLastPlacesRegistered() {
      // Loading
      this.processingRequestRegister = true
      try {
        // Obtenemos los establecimientos
        this.placesRegistered = await getEveryPlaces({
          constraints: [['brand', '==', this.companyData.brand]],
          order: {
            field: 'createTimestamp',
            direction: 'desc'
          },
          limit: QUERY_LIMITS[2]
        })
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequestRegister = false
      }
    }
  }
}
