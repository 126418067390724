// Filters
import { formatNumber } from '@/filters'

export default {
  name: 'DataColumns',
  filters: { formatNumber },
  props: {
    data: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    /**
     * ¿El valor es positivo o negativo?
     *
     * @return {boolean}
     */
    isPositive(value) {
      return value >= 0
    }
  }
}
