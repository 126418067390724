// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatDate } from '@/filters'

export default {
  name: 'PlacesListItem',
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: { formatDate },
  mixins: [uiMixin],
  methods: {
    /**
     * Cuando pulsamos sobre el botón de detalle
     *
     * @param {string} id - UID del establecimiento
     */
    handleClickDetail(id) {
      this.routerPushTo({
        name: 'places-detail',
        params: {
          id
        }
      })
    }
  }
}
